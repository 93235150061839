<!-- AulasLancadas -->
<template>
  <v-col cols="12" md="3" sm="12">
    <v-card :loading="loading" class="mx-auto" height="180">
      <template slot="progress">
        <v-progress-linear color="indigo" indeterminate></v-progress-linear>
      </template>

      <!-- <v-card-text class="text-h6 font-weight-bold">
        Aulas Confirmadas
      </v-card-text> -->

      <v-card-text>
        <div class="text-h6 font-weight-black">Aulas Confirmadas</div>
        <!-- <span>7 dias</span> -->
      </v-card-text>

      <v-row style="margin-top: -30px">
        <v-col cols="7">
          <v-card-text class="text-h4 font-weight-bold">
            {{ qtd ?? 0 }}
          </v-card-text>
        </v-col>

        <v-col cols="5">
          <apexchart
            class="ml-4"
            type="area"
            height="150"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    franquiaId: {
      type: Number,
    },
  },
  name: "AulasConfirmadasLancadasAno",
  mounted() {
    this.getQtd(this.franquiaId);
  },
  watch: {
    qtd() {
      this.chartOptions = {
        xaxis: {
          categories: [...this.datas],
        },
      };
    },
    franquiaId(value) {
      this.getQtd(value);
    },
  },
  data() {
    return {
      datas: [],
      qtd: 0,
      loading: true,

      series: [
        {
          name: "Aulas",
          data: [80, 88, 55, 888],
        },
      ],

      chartOptions: {
        chart: {
          height: 150,
          type: "area",
          toolbar: {
            show: false,
          },
          offsetY: -30,
        },

        dataLabels: {
          enabled: false,
        },

        grid: {
          show: false,
        },

        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            // stops: [0, 90, 100],
          },
        },

        yaxis: {
          show: false,
        },

        responsive: [
          {
            breakpoint: 200,
            options: {
              legend: {
                position: "top",
                offsetY: -80,
              },
            },
          },
        ],

        xaxis: {
          labels: {
            show: false,
          },
          // categories: [1, 2, 3],
        },
      },
    };
  },
  methods: {
    async getQtd(franquiaId) {
      const response = await this.$services.aulasService.AulasConfirmadasAno(franquiaId);

      const dataCategories = response.data.aulasDoAno.map((d) =>
        d.data.substring(5).split("-").reverse().join("-")
      );
      this.datas = [...dataCategories];

      this.qtd = response.data.totalDeAulas;
      const dataSeries = response.data.aulasDoAno.map((q) => q.total);

      this.series = [];
      this.series.push({
        name: "Aulas",
        data: [...dataSeries],
      });

      this.loading = false;
    },
  },
};
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
